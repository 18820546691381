
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.header {
  display: flex;
  height: 73px;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;

  .logo {
    cursor: pointer;
    height: 100%;
    max-width: 12.5rem;
  }

  .iconWrapper {
    width: 100%;
    // max-width: 290px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 24px;
    :global {
      .willeder-button.addButton {
        max-width: 220px;
        @media (max-width: 620px) {
          font-size: 2.5vw !important;
          // height: 30px;
        }
      }
    }

    .icon {
      width: 100%;
      max-width: 40px;
      height: 40px;
      cursor: pointer;
      transition: transform 0.3s ease;
      touch-action: manipulation;
      z-index: 999;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: scale(1.1);
        background-color: rgba(211, 211, 211, 0.633);
        svg {
          path {
            fill: black;
          }
        }
      }
    }

    .switchContainer {
      display: flex;
      align-items: center;

      .accountText {
        color: #000;
        text-align: center;
        font-family: 'Noto Sans JP';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer;
        min-width: 145px;
      }
    }
  }

  .menuItems {
    display: flex;
    width: 262px;
    // padding: 10px 20px;
    flex-direction: column;
    align-items: flex-start;
    // gap: 20px;
    background: #1e4178;
    position: absolute;
    right: 22px;
    top: 70px;
    z-index: 999;

    .value {
      color: #fff;
      font-size: 16px;
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      // line-height: 100%;
      width: 100%;
      padding-block: 15px;
      cursor: pointer;
      padding-left: 20px;

      will-change: transform;
      transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        background-color: #152e55;
        transform: scale(1.02);
      }
    }
  }
}

@media (max-width: 767px) {
  .header {
    .iconWrapper {
      // max-width: fit-content;
      gap: 12px;

      :global .label {
        padding: 10px;

        span {
          font-size: 10px;
        }

        input {
          width: 35px;
          height: 15px;

          &::before {
            top: 1px;
            left: 1px;
            width: 13px;
            height: 13px;
          }
        }
      }

      .switchContainer {
        .accountText {
          font-size: 10px;
          min-width: 100px;
          width: 100%;
        }
      }

      .icon {
        width: 25px;
        height: 25px;
      }
    }

    .logo {
      width: 150px;
    }

    .menuItems {
      max-width: 210px;
      padding: 10px;
      right: 15px;
      z-index: 1;

      .value {
        font-size: 14px;
        /* Adjusted font size for smaller screens */
      }
    }
  }
}

// TODO: no need, this need to check and remove
@media only screen and (max-width: 768px) {
  .iconWrapper {
    align-items: center !important;

    button {
      font-weight: bold !important;

      // font-size: 10px !important;
      :only-child {
        // display: none;
      }
    }
  }
}

@media only screen and (max-width: 376px) {
  .header {
    .logo {
      max-width: 100px;
    }
  }

  .iconWrapper {
    align-items: center !important;

    button {
      font-weight: bold !important;
      font-size: 10px !important;

      :only-child {
        display: none;
      }
    }
  }
}
