.willeder-modal-overlay-scope {
  background-color: rgba(150, 150, 150, 0.822);
  backdrop-filter: blur(4px);
  padding: 10px;

  .willeder-modal-scope {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    flex-direction: column;
    align-items: end;
    // min-height: 507px;
    // min-height: 600px;
    min-height: auto;
    // height: 100%;
    padding: 55px;
    box-shadow:
      0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075),
      0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075),
      0 30px 60px -12px hsla(240, 30%, 28%, 0.25),
      0 18px 36px -18px hsla(0, 0%, 0%, 0.3);

    border-radius: 15px;
    margin: 10px;

    @media (min-width: 1020px) {    
      &:has(.willeder-modal-content-scope .fill-width) {
        // max-width: 50vw;
        max-width: fit-content;
      }  
    }
    @media only screen and (max-width: 1550px) {
      &:has(.willeder-modal-content-scope .fill-width) {
        // height: 100%;
        height: fit-content;
      }
    }

    // &:has(.gTransferModalStyle){
    //   max-width: 520px;
    // }

    .willeder-modal-content-scope {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      align-items: center;
    }
    .willeder-modal-close-scope{
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    &:has(.subscription) {
      // max-width: 875px;
      max-width: 100%;
    }

    .subscription {
      height: 0px;
    }

    &:has(.transferModal) {
      padding: 0;
      max-width: 80vw;
      .closeModal{
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        cursor: pointer;
      }

      .willeder-modal-close-scope{
        margin-block: 20px;
        padding-right: 20px;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2b2b2c;
        outline: 1px solid slategrey;
      }

    }

    &:has(.dragDropModal) {
      max-width: 900px;
      height: 100%;
      max-height: 588px;
      padding: 0px;
    }
  }

  @media only screen and (max-width: 768px) {
    .willeder-modal-scope {
      padding: 27px;
      margin: 0;
      // &:has(.subscription){
      //   display: flex;
      //   flex-direction: column;
      // }
    }

  }

}