.willeder-inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: 404px;

  .willeder-input {
    width: 100%;
    height: 43px;
    padding: 0px 10px;
    border-radius: 6px;
    border: 1px solid $clr-black-100;
    font-size: $font-base;
    &::placeholder {
      color: $clr-black-100;
      font-size: $font-base;
      font-weight: $font-500;
    }
  }
  .willeder-input-label {
    color: $clr-black-700;
    font-size: $font-base;
    font-style: normal;
    font-weight: $font-400;
    line-height: normal;
  }
  .willeder-input-error{
    color: $clr-red-200;
    font-size: $font-xs;
    font-weight: $font-400;
  }
}
.card {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .willeder-input {
    width: 100%;
    max-width: 196px;
    height: 43px;
    padding: 0px 10px;
    border-radius: 10px;
    border: none;
    background-color: #e2e6ee;
    &::placeholder {
      color: #828282;
      font-size: $font-xs;
      font-weight: $font-500;
    }
  }
  .willeder-input-label {
    color: $clr-black-700;
    font-size: $font-base;
    font-style: normal;
    font-weight: $font-400;
    line-height: normal;
  }
}
