
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.confirmModal {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  .title {
    font-size: 23px;
    text-align: center;
    color: $clr-black-700;
  }
  .code {
    color: $clr-black-200;
    font-size: $font-xl;
    font-style: normal;
    font-weight: $font-400;
  }
  p {
    color: $clr-black-700;
    text-align: left;
    font-weight: $font-400;
    line-height: 115%;
    font-size: 18px;
    line-height: inherit;
  }
  h2 {
    text-align: center;
    line-height: 115%;
    font-size: 23px;
    text-align: center;
    color: #1E1E1E;
  }
  .linkText {
    width: 100%;
    max-width: 404px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    line-height: normal;
    color: $clr-black-700;
    font-size: $font-base;
    font-weight: $font-400;
  }
  .buttonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    .buttonIconWrapper{
      margin-right: 10px;
      svg{
        path{
          fill: white;
        }
      }
    }
  }
  .caution{
    width: 100%;
    .cautionMessageText{
      text-align: left;
    }
  }
  .crypto{
    margin-block: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
