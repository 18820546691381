
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.accordion {
  width: 100%;
  padding: 10px;
  overflow: auto;
  .cursorPointer {
    cursor: pointer;
  }

  .accordionItem {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wrapper {
      width: 100%;
      max-width: 200px;
      display: flex;
      align-items: center;
      gap: 15px;
      flex-grow: 1;
      flex-basis: max-content;

      .button {
        width: 100%;
        max-width: 40px;
        border: none;
      }

      .title {
        width: 100%;
        max-width: 100px;
        word-wrap: break-word;
      }
    }

    .fileDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
      max-width: 600px;

      .filesz {
        padding-right: 90px;
        font-size: 16px;
      }

      .line {
        width: 100%;
        max-width: 200px;
        height: 1px;
        background-color: #ccc;
      }

      .fileStatus {
        width: 100%;
        max-width: 650px;
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;

        .fileparent {
          padding: 0px 0px;
        }
      }
    }

    input[type='checkbox'] {
      margin-right: 10px;
    }

    button {
      background: none;
      cursor: pointer;
    }

    .icons {
      width: 100%;
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      flex-grow: 1;
    }
  }

  .accordionChildren {
    padding-left: 10px;
  }

  .accordionchild {
    padding-left: 33px;
    border-bottom: 1px solid #d9d9d9;
  }

  @media only screen and (max-width: 968px) {
    .accordionItem {
      .button {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .accordionItem {
      .wrapper {
        max-width: fit-content;
        .title {
          max-width: 60px;
        }
      }
      .fileDetails {
        .line {
          max-width: 100px;
        }
        gap: 5px;
        max-width: 340px;
        .filesz {
          font-size: 14px;
          padding-right: 90px;
          max-width: 139px;
        }
        .fileStatus {
          padding: 0px 5px;
          .fileparent {
            font-size: 14px;
          }
        }
      }

      .icons {
        max-width: 180px;
        gap: 10px;
        svg {
          max-width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 5px;
    .accordionItem {
      margin: 0px;
      padding: 5px;
      flex-wrap: wrap;
      row-gap: 10px;
      input[type='checkbox'] {
        margin: 0px;
      }

      .wrapper {
        max-width: 85px;
        gap: 5px;
        .title {
          font-size: 14px;
        }
      }
      .icons {
        max-width: 100%;
      }

      .fileDetails {
        align-items: flex-start;
        justify-content: flex-start;
        max-width: fit-content;
        .line {
          max-width: 0px;
        }
        .filesz {
          max-width: unset;
        }
        .fileStatus {
          max-width: 172px;
          padding: 0px 10px;
          gap: 25px;

          .fileparent {
            font-size: 12px;
          }
        }
      }
    }
    .accordionchild {
      padding: 0px 10px;
    }
  }
}

.tableWrapper {
  width: 100%;
  overflow: auto;
  padding: 20px;
  height: 100%;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2b2b2c;
    outline: 1px solid slategrey;
  }
}
.accordionTable {
  width: 100%;

  tbody {
    td.detailsControl {
      cursor: pointer;
      // width: 100%;
      // max-width: 200px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      // white-space: normal;
    }
  }

  .tableHeading {
    text-align: left;
    min-width: 200px;
  }
  tr {
    vertical-align: baseline;
  }

  td,
  th {
    padding: 10px;
    gap: 10px;
    input {
      cursor: pointer;
    }
  }
  .tdCheckboxStyle {
    background-color: white;
  }

  .detailsControl {
    width: 600px;

    .bgBorderNone {
      background: none;
      border: none;
    }
  }
  .nested {
    padding-left: 40px;
  }
  @media (max-width: 960px) {
    width: 110%;
    .detailsControl {
      width: unset;
    }
  }
}

.hLineRelative {
  position: relative;
  height: 100%;
  width: 20%;
  .hLineAbsolute {
    height: 2px;
    width: 100%;
    border-bottom: 1px solid #111;
    float: left;
    margin: 0;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
  }
}
.iconWrapperFlex {
  display: flex;
  align-items: baseline;
  background-color: white;

  *:nth-last-child(2) {
    gap: 20px;
  }

  :only-child {
    cursor: pointer;
  }
  .lastChildIconWrapper {
    display: flex;
    gap: 17px;
  }
}

.flexEnd {
  justify-content: flex-end;
}
.spaceBetween {
  justify-content: space-between;
  :global {
    .tooltip-container {
      cursor: pointer;
      touch-action: manipulation;
      will-change: transform;
      transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        transform: scale(1.09);
      }
    }
  }
}
@media (max-width: 780px) {
  .tableSpacer,
  .hLineRelative {
    display: none;
  }
}
