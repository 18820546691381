
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.otpGroup {
  display: flex;
  width: 100%;
  max-width: 520px;
  gap: 30px;
}
.otpInput {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: $font-lg;
  font-weight: $font-400;
  line-height: 1;
  &:focus {
    outline: none;
    border-color: #2196f3;
    background-color: #ffffff;
  }
}
