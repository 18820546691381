
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.progressbar {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .uploadMeter {
    width: 187px;
    position: relative;

    &::-webkit-meter-bar {
      background-color: rgba(15, 145, 210, 0.18);
      border-radius: 40px;
      height: 10px;
    }

    &::-webkit-meter-optimum-value,
    &::-webkit-meter-suboptimum-value,
    &::-webkit-meter-even-less-good-value {
      background-color: #0f91d2;
      border-radius: 40px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 40px;
      background: rgba(15, 145, 210, 0.18);
      z-index: -1;
      height: 20px;
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: 12px;
      width: 100%;
    }

    .uploadMeter {
      width: 100%;
    }
  }
}
