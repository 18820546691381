
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.transferModal {

    .titleWrapper,
    .description,
    .tips,
    .checkboxWrapper,
    .btnWrapper {
        margin-bottom: 24px;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2b2b2c;
        outline: 1px solid slategrey;
    }

    h1 {
        color: #1E1E1E;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
    }

    .description {
        display: flex;
        width: 100%;
        max-width: 419px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;

        p {
            color: #1E1E1E;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 115%;
        }

        .content {
            color: #1E1E1E;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 115%;
        }
    }

    .tips {
        width: 100%;
        max-width: 419px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .checkboxWrapper {
        width: 100%;
        max-width: 419px;
    }
}