
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.successBox {
  display: flex;
  width: 100%;
  padding: 0 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 25px;

  h2 {
    color: $clr-navy-200;
    font-size: $font-xl;
    font-weight: $font-500;
    line-height: 52px;
  }
  p {
    color: #828282;
    font-size: $font-base;
    font-weight: $font-400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}
