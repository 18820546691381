
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.confirmModal {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    padding: 20px 0px;
    .title {
      color: $clr-black-700;
      font-size: 23px;
      text-align: center;
    }
    .code{
        color: $clr-black-200;
        font-size: $font-xl;
        font-style: normal;
        font-weight: $font-400;
    }
    p{
      color: $clr-black-700;
      // text-align: center;
      // font-size: $font-lg;
      font-size: 18px;
      font-weight: $font-400;
      line-height: inherit; 
      width: 100%;
    }
    h2{
      color: $clr-black-700;
      text-align: center;
      font-size: $font-lg;
      font-weight: $font-400;
      line-height: 115%; 
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }

  }
  