
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.spaceSetting {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;
  height: 100%;

  .title {
    color: $clr-navy-200;
    font-size: $heading-1;
    font-weight: $font-500;
  }

  .yearPlan {
    display: flex;
    width: 100%;
    max-width: 435px;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 11px;

    .monthly {
      display: flex;
      width: 100%;
      max-width: 200px;
      height: 50px;
      justify-content: center;
      align-items: center;
      border: 1px solid $clr-navy-200;
      color: $clr-navy-200;
      font-size: $font-base;
      font-weight: $font-700;
    }

    .yearly {
      display: flex;
      width: 100%;
      max-width: 200px;
      height: 50px;
      justify-content: center;
      align-items: center;
      background-color: #D3E7F0;
      color: $clr-navy-200;
      font-size: $font-base;
      font-weight: $font-700;
    }
  }

  .plans {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    // gap: 20px;
    // max-height: 500px;
    // flex-wrap: wrap;
    // overflow: overlay;
    
    @media (min-width: 1020px) {
      // overflow-y: clip;
      padding-inline: 0;
      // padding-inline: 5rem;
    }


    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D3E7F0;
      outline: 1px solid slategrey;
    }

    .plans1,
    .plans2 {
      display: flex;
      // flex: 1;
      width: 100%;
      // max-width: 362px;
      // height: 100%;
      padding: 23px 10px;
      flex-direction: column;
      align-items: center;
      gap: 18px;
      flex-shrink: 0;
      border-radius: 10px;
      border: 1px solid $clr-navy-200;
      background: #FFF;
      transition: background-color 0.7s cubic-bezier(0.25, 0.8, 0.25, 1), 
      transform 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
      // transition: background 0.3s ease-in-out;
      transform: scale(0.9);
      @media (min-width: 1020px) {
        flex: 1;
        max-width: 380px;
        padding-block: 40px;
      }


      .amount {
        color: $clr-navy-200;
        font-size: 60px;
        font-style: normal;
        font-weight: $font-500;
        line-height: normal;
      }

      .userMonth {
        color: #81A7BA;
        font-size: $font-base;
        font-weight: $font-700;
      }

      .subDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 14px;
        width: 100%;

        .content {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          width: 100%;

          .flexItem {
            display: flex;
            align-items: baseline;
            width: 100%;
            flex: 1;
            @media (min-width: 1020px) {
              width: 340px;
            }

            svg {
              stroke: green;
            }
          }

          .cardContentPara {
            margin-left: 6px;
          }

          .flexItemFlexEnd {
            text-align: right;

            .fileItemUnit {
              margin-left: 5px;
            }
          }

          p,
          span {
            color: $clr-navy-200;
            font-size: $font-base;
            font-weight: $font-400;
          }
        }
      }

      &:hover,
      &:active {
        background: $clr-navy-200;
        transform: scale(0.95);

        svg {
          stroke-width: 1.6px;
        }

        button {
          border-color: $clr-white-100;
          color: $clr-white-100;
        }

        .choosesPlan {
          width: 100%;
          max-width: fit-content;

        }

        .amount {
          color: $clr-white-100;

          p {
            color: $clr-white-100;
          }
        }

        .subDetails {
          .content {

            p,
            span {
              color: $clr-white-100;
            }

            .flexItem {

              svg {
                stroke: white;
              }
            }
          }
        }
      }

      .planButton {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          color: $clr-navy-200;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    max-height: 700px;
    // overflow: auto;
    .title{
      font-size: 25px;
    }

    .plans {
      // flex-direction: column;
      // max-height: unset;
      // flex-wrap: nowrap;
      overflow: auto;
    }
  }

  @media only screen and (max-width: 1540px) {
    .plans {
      justify-content: flex-start;
      flex-wrap: nowrap;
      // flex-direction: column;
      overflow: overlay;
      // max-height: 340px;
    }

    .yearPlan {
      .yearly {
        font-size: 13px;
        text-align: center;
      }
    }

    .willeder-modal {
      padding: 15px;
      margin: 0;
    }
  }
  // .SubscriptionModal_spaceSetting {
    // max-height: 700px;
  // }
}