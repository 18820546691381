@import './design-tokens/breakpoints';
@import './design-tokens/colors';
@import './design-tokens/effects';
@import './design-tokens/mixins';
@import './design-tokens/typography';
@import './components/button.scss';
@import './components/input.scss';
@import './components/modal.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,body{
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: 'Noto Sans JP', sans-serif;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

.react-tooltip {
  background: #0e0e0ee4; /* Tooltip background color */
  color: #EDEBE4; /* Tooltip text color */
  position: absolute; /* Positioning */
  border-radius: 18px;
  z-index: 9999; /* Ensure it's above other elements */
  display: block; /* Ensure it's displayed */
  // max-width: 200px; /* Adjust as needed */
  white-space: nowrap; /* Prevent text wrapping */
  display: flex;
}

.react-tooltip__content {
  padding: 4px 8px; /* Padding around the content */
  font-size: 12px; /* Font size */
  font-family: 'Open Sans', sans-serif; /* Font family */
}

.toolTipContentWrapper{
  position: absolute;
  top: 0;
  left: 0;
}


