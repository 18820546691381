$clr-black-100:#d9d9d9;
$clr-black-200: #656565;
$clr-black-300: #808192;
$clr-black-400: #828282;
$clr-black-500: #545454;
$clr-black-600: #242533;
$clr-black-700: #1E1E1E;
$clr-black-800: #000000;     //space-setting


$clr-white-100: #F3F3F3;
$clr-white-200: #F5F5F5;

$clr-red-100: #FF4949;
$clr-red-200: #FF2626;
$clr-red-300: #ff0000;       //space-setting


$clr-blue-100: #0070c0;      //space-setting
$clr-blue-200: #0F3DDE;
$clr-blue-300: #0038C8;

$clr-navy-100: #0070C0;
$clr-navy-200: #1E4178;
$clr-navy-300: #002060;      //space-setting


$clr-sky-100: #00ADEF;
$clr-sky-200: #0F91D2;

$clr-green-100: #73b08e;     //space-setting
$clr-green-200: #00b04f;     //space-setting
$clr-green-300: #508527;
$clr-green-400: #00800D;

$clr-yellow-100: #FFC700;

$clr-orange-100: #ed7c31;    //space-setting

$clr-brown-100: #c05000;     //space-setting


$space-colors : ($clr-orange-100,$clr-navy-300,$clr-brown-100,$clr-green-100,$clr-green-200,$clr-blue-100,$clr-red-300,$clr-black-700 );

@mixin generate-color-styles($colors) {
    @each $color in $colors {
      $class-index: index($colors, $color);
      $class-name: nth(('orange', 'navy', 'brown', 'lightgreen', 'darkgreen', 'blue', 'red', 'black'), $class-index);
      
      .#{$class-name} {
        background-color: rgba($color, 0.20);
      }
      .card-#{$class-name} {
        border-bottom: 6px solid $color;
      }
      .text-#{$class-name} {
        color:$color;
      }
    }
  }
  
@include generate-color-styles($space-colors);
