
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.confirmModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  // padding: 20px 0px;
  width: 100%;
  .description {
    color: $clr-black-700;
    // font-size: $font-lg;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  @media only screen and (max-width: 768px) {
    .description {
      font-size: $font-base;
    }
  }
}
