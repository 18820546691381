
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.generateModal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 20px 0px;
    .title {
      font-size: $heading-1;
    }
    .code{
        color: $clr-black-200;
        font-size: $font-xl;
        font-style: normal;
        font-weight: $font-400;
    }
    .description{
        text-align: justify;
        padding: 0px 20px;
        color: $clr-black-700;
        // font-size: $font-lg;
        font-size: 18px;
        font-weight: $font-400;
        // line-height: 115%;
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }
    @media only screen and (max-width: 768px) {
      .title{
        font-size: $font-md;
      }
      .description{
        font-size: $font-md;

      }
    }
  }
  