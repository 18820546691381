
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        

.addSpace {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 20px 0px;
    .title {
      font-size: 23px;
      font-weight: $font-500;
      text-align: center;
    }
    .para{
        width: 100%;
        max-width: 400px;
        font-size: 18px;
    }

    .color{
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        .subTitle{
            font-size: $font-base;
            font-weight: $font-500;
        }
        .spaceColors{
            width: 100%;
            max-width: 404px;
            display: flex;
            align-items: center;
            gap: 16px;
            position: relative;
            color: #333;
        }
    }
    
    details{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        padding: 10px 0px;
        align-items: flex-start;
        summary{
            color: $clr-black-700;
            font-size: $font-base;
            font-style: normal;
            font-weight: $font-500;
            line-height: 100%; 
        }
        .settingContent{
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }
    .inputRange{
        display: flex;
        justify-content: space-between;
        align-items: center;
        input{
            display: flex;
            padding: 8px 5px;
            width: 100%;
            max-width: 78px;
            border-radius: 6px;
            border: 1px solid #D9D9D9;
            background: #FFF;
        }
    }
    
    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  