
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.alert {
  width: 100%;
  max-width: fit-content;
  display: flex;
  padding: 20px 48px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10001;
}

.success {
  border: 0.5px solid #00800d;
  color: #00800d;
}

.error {
  border: 0.5px solid #ff2626;
  color: #ff2626;
}

.info {
  border: 0.5px solid #0f91d2;
  color: #0f91d2;
}

.icon {
  width: 24px;
  height: 24px;
}

.message {
  font-size: 16px;
  width: 100%;
}

.block {
  width: 100%;
  max-width: 491px;
  display: flex;
  flex-direction: column;
  padding: 20px 26px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10001;
  .blockMessage {
    display: flex;
    align-items: center;
    text-align: center;
  }
 
}
@media only screen and (max-width: 768px) {
  .alert{
    padding: 20px 24px;

  }
}