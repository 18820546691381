
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.confirmModal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    // padding: 20px 0px;
    width: 100%;
    h1{
      font-size: $heading-1;
      text-transform: capitalize;
    }
    .description{
        color: $clr-black-700;
        // font-size: $font-lg;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
    .columnAlignCenter{
      flex-direction: column;
      align-items: center;
    }
  @media only screen and (max-width: 768px) {
    h1{
      font-size: $font-lg;
    }
    .description{
      font-size: $font-base;
    }
  }

  }
  