
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .messageBox{
        display: flex;
        width: 100%;
        max-width: 720px;
        padding: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        border-radius: 7px;
        background: #FFF;
        box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.12);
        h2{
            color: $clr-navy-200;
            font-size: $font-xl;
            font-weight: $font-500;
            line-height: 52px;
        }
        p{
            color: #828282;
            font-size: $font-base;
            font-weight: $font-400;
            line-height: 24px; 
            letter-spacing: 0.15px;
        }
    }
}