
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.confirmModal {
    display: flex;
    flex-direction: column;
    // gap: 18px;
    gap: 25px;
    align-items: center;
    // padding: 20px 0px;
    .title {
      // font-size: $heading-1;
      font-size: 23px;
      color: $clr-black-700;
      text-align: center;
    }
    .Subtitle{
      text-align: left;
      font-size: 18px;
    }
    .code{
        color: $clr-black-200;
        font-size: $font-xl;
        font-style: normal;
        font-weight: $font-400;
    }
    p{
      color: $clr-black-700;
      text-align: center;
      font-size: $font-lg;
      font-weight: $font-400;
      line-height: 115%; 
      font-size: 21px;
      line-height: inherit;
    }
    h2{
      color: $clr-black-700;
      text-align: center;
      font-size: $font-lg;
      font-weight: $font-400;
      line-height: 115%; 
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
    }
    @media only screen and (max-width: 768px) {
      .title{
        font-size: $font-md;
      }
      p{
        font-size: $font-md;
      }
    }

  }
  