/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.selectedFileItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

}

.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  max-width: 70vw;
  max-height: 60vh;
  height: 100%;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
  position: relative;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.selectFiles {
  font-size: 28px;
}

.selectFilesSM {
  font-size: 24px;
}

.file-upload-modal.dragging {
  border: 2px dashed #007bff;
  background-color: #f9f9f9;
}

.file-upload-button {
  /* display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px; */
  display: block;
  width: 100%;
  max-width: 280px;
  text-align: center;
  padding: 10px 0px;
  border-radius: 5px;
  font-style: normal;
  line-height: normal;
  touch-action: manipulation;
  will-change: transform;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #fbfdfe;
  border: 1px solid rgba(15, 145, 210, 0.7);
  color: #0f91d2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.file-upload-button:hover {
  transform: scale(1.05);
}

.selected-files {
  margin-top: 20px;
  font-size: 14px;
}

.selected-files h3 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.selected-files ul {
  list-style-type: none;
  padding: 0;
}

.selected-files li {
  margin: 5px 0;
  color: #333;
  word-wrap: break-word;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
