
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.editModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 20px 0px;
  width: 100%;

  .title {
    font-size: $heading-1;
  }

  .para {
    width: 100%;
    max-width: 400px;
    padding: 0px 10px;
  }

  .color {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    .subTitle {
      font-size: $font-base;
      font-weight: $font-500;
    }
    .spaceColors {
      width: 100%;
      max-width: 404px;
      display: flex;
      align-items: center;
      gap: 16px;
      position: relative;
      color: #333;
    }
  }

  .inputRange {
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      display: flex;
      padding: 8px 5px;
      width: 100%;
      max-width: 78px;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      background: #fff;
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .createAtWrapper{
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    .text{
      font-size: 12px;
    }
  }
}
