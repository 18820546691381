
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh 10px;
  gap: 15px;
  @media (max-width: 430px) {
    padding-block: 5vh;
  }
  .switch {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0px;
    gap: 24px;

    h1 {
      font-size: $font-lg;
      font-weight: $font-700;
    }
  }
  .ordivider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 404px;

    .line {
      flex: 1;
      height: 1px;
      background-color: $clr-white-200;
    }
    p {
      padding: 0 10px;
      color: #888;
    }
  }
  .account {
    color: $clr-black-600;
    font-size: $font-base;
    font-style: normal;
    font-weight: $font-500;
    line-height: normal;
    .signin {
      color: $clr-blue-200;
    }
  }
  .termsBox {
    cursor: pointer;
    label {
      color: $clr-navy-200;
      text-decoration: underline;
      font-size: 14px;
    }
  }
}
