
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.notificationItem {
  display: flex;
  width: 100%;
  max-width: 400px;
  height: fit-content;
  padding: 10px 5px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2px solid #7E7E7E;

  .leftContent {
    display: flex;
    height: 61px;
    padding: 2px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .name {
    color: $clr-black-700;
    font-size: $font-base;
    font-weight: $font-700;
    line-height: normal;
  }

  .time { 
    color: $clr-red-200;
    font-size: $font-xs;
    font-style: normal;
    font-weight: $font-400;
    line-height: normal;
  }

  .requestText {
    color: #6A6A6A;
    font-size: $font-xs;
    font-style: normal;
    font-weight: $font-400;
    line-height: normal;
  }
  .rightContent{
    display: flex;
    padding: 3px 0px;
    align-items: center;
    gap: 10px;
  }
}
