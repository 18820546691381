
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-end;
  padding: 31px 20px;

  span {
    color: $clr-black-700;
    font-size: $font-base;
    font-weight: $font-400;
    line-height: 100%;
    margin: 0 4px;
  }

  input {
    position: relative;
    appearance: none;
    width: 40px;
    height: 20px;
    border-radius: 10px;
    background-color: #ccc;
    outline: none;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: white;
      transition: transform 0.3s ease;
    }

    &:checked {
      background-color: grey;

      &::before {
        transform: translateX(20px);
      }
    }
  }
}