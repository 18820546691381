
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-right: 15px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  &.space {
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
    align-items: center;
    margin-right: 0px;
    .checkmark {
      position: relative;
    }
  }
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #989898;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-checkbox input:checked + .checkmark {
  background-color: #2196f3;
  border: 1px solid #2196f3;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked + .checkmark:after {
  display: block;
}

.custom-checkbox input:checked + .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
