
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.editModal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
  .title {
    font-size: $heading-1;
  }
  .buttonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .card-input {
    border-radius: 6px;
    border: 1px solid $clr-black-100;
    padding: 12px 10px;
    width: 100%;
    align-items: center;
  }
}
