
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;

  .cover {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    .top {
      padding: 5px 0px 0px 58px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      h1 {
        width: 100%;
        max-width: fit-content;
        color: $clr-black-700;
        font-size: $font-xl;
        font-weight: $font-500;
        line-height: 140%;
      }
    }
  }
  .dragWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 54px;
    gap: 10px;
    flex-shrink: 0;
    width: 100%;

    .dragDropBox {
      display: flex;
      width: 100%;
      height: 75vh;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.12);
      flex-wrap: wrap;
      .heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 20px 30px;
        justify-content: flex-start;
        .content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h1 {
            padding: 0px 50px;
            font-size: $font-base;
            font-weight: $font-700;
          }
          .main {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 33px;
            width: 100%;
            max-width: 810px;
            p {
              padding: 0px 45px;
              font-size: $font-base;
              font-weight: $font-700;
            }
          }
        }
      }

      .buttonWrapper {
        display: flex;
        width: 100%;
        height: 92px;
        padding: 10px 40px 5px 40px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        background: #fbfdfe;
      }
    }
  }
}
@media (max-width: 767px) {
  .wrapper {
    .dragWrapper {
      .dragDropBox {
        .heading {
          .content {
            h1 {
              font-size: $font-base;
            }
            .main {
              gap: 16px;
              flex-wrap: wrap;
              p {
                padding: 0px;
                font-size: $font-base;
              }
            }
          }
        }

        .container {
          .dropzone {
            gap: 16px;
            padding: 10px;
            p {
              font-size: $font-lg;
            }

            .before,
            .afterWrapper {
              gap: 12px;
            }

            aside {
              padding: 5px 16px;
              .heading {
                padding: 0px;
              }
              .lists {
                gap: 8px;

                li {
                  .fileInfo {
                    p {
                      font-size: $font-base;
                    }
                    .right {
                      gap: 8px;
                      p {
                        font-size: $font-xs;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .wrapper{
    flex-direction: column; 
  }
}