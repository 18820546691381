
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.heading {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.termsSection {
  margin-bottom: 20px;
  line-height: 1.6;
  p{
    padding-left: 40px;
  }
  li{
    font-size: 14px;
  }
  .orderList, .numberedList{
    li:not(:last-child){
      margin-bottom: 10px;
    }
  }
}

.termsModal {
  overflow-y: auto;
  p{
    line-height: 1.714;
  }
}

pre {
  white-space: pre-wrap;
}
.date{
  text-align: right;
  width: 100%;
  margin-bottom: 20px;
}

.termsModal {
  background-color: white;
  max-height: 80vh;
  padding: 20px 30px;
  margin-bottom: 20px;
  padding-bottom: 0;

  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
  }
   
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
   
  &::-webkit-scrollbar-thumb {
    background-color: #2b2b2c;
    outline: 1px solid slategrey;
  }
}

.termsContent {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.subheading {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
.numberedList {
  counter-reset: list-counter;
  list-style: none;
  padding-left: 0;
  margin-left: 10px;
  margin-top: 10px;
}

.numberedList li {
  counter-increment: list-counter;
  position: relative;
  padding-left: 2em;
}

.numberedList li::before {
  content: "( " counter(list-counter) " )";
  position: absolute;
  left: -20px;
  width: 3em;
  text-align: right;
}