
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.molecule {
  display: flex;
  width: 100%;
  max-width: 220px;
  height: 40px;
  // padding: 2px 16px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 475px) {
    padding: 0;
  }

  .userDetails {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2px;

    .userName {
      color: #808192;
      font-size: $font-sm;
      font-style: normal;
      font-weight: $font-500;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100px;
    }

    .statusContainer {
      display: flex;
      align-items: center;
      gap: 3px;

      .status {
        font-size: $font-xs;
      }
    }
  }

  .right {
    // display: inline;
    display: flex;
    align-items: center;
    gap: 10px;

    .default {
      copyIcon {
        path {
          fill: black;
        }
      }
    }

    .waiting,
    .unread {
      .copyIcon {
        path {
          // fill: #ffc700;
          fill: #FFA500;
        }
      }
    }

    .expired,
    .deactivate,
    .deactivated {
      .copyIcon {
        path {
          fill: #ff2626;
        }
      }
    }

    .active,
    .read {
      svg {
        path {
          fill: #00800d;
        }
      }
    }

    .copyIcon,
    .menuIcon {
      width: 24px;
      height: 24px;
      // margin-left: 8px;
      cursor: pointer;

      @media (max-width: 900px) {
        margin-left: 0;
      }

      touch-action: manipulation;
      will-change: transform;
      transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        transform: scale(1.06);
      }
    }
    .unread,
    span .menuIcon {
      // TODO: need to fix
      margin: 0 !important;
      padding: 3px;

      cursor: pointer;
      z-index: 999;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: rgba(214, 214, 216, 0.644);
      }
    }

    .menuItems {
      z-index: 99;
      display: flex;
      width: auto;
      // max-width: 134px;
      height: 100%;
      max-height: 190px;
      padding: 8px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      position: absolute;
      top: 154px;
      left: 73px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.02);

      .item {
        display: flex;
        height: 20px;
        padding: 14px 8px;
        align-items: center;
        align-self: stretch;
        cursor: pointer;
        color: #455a64;
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;

        &:hover {
          padding: 14px 8px;
          border-radius: 5px;
          background: #f0f5ff;
        }
      }
    }
  }
}
