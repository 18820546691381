
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.willeder-modal-overlay-scope {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .willeder-modal-scope {
    background-color: #fff;
    // padding: 20px;
    // border-radius: 5px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // position: relative;
    // width: auto; 
    .willeder-modal-close-scope {
      // position: absolute;
      // top: 10px;
      // right: 10px;
      // border: none;
      // background: none;
      // font-size: 1.5rem;
      // cursor: pointer;
    }
    .willeder-modal-content-scope {
      // Add styles for the content area if needed
    }
  }
}