
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.receiverModal {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    padding: 20px 0px;
    width: 100%;
    .title {
      font-size: $heading-1;
      color: $clr-black-700;
    }
    .buttonWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
@media (max-width: 767px) {
  .title{  
    font-size: 24px;
  }
  }
  .createdAtWrapper{
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 100%;
    .text{
      font-size: 12px;
    }
  }
}
  