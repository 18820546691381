
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper {
  display: flex;
  width: 100%;
  // height: 100vh;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;

  .cover {
    display: flex;
    flex-direction: column;
    width: 100%;
    .top {
      padding: 5px 0px 0px 58px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 16px;
      h1 {
        width: 100%;
        max-width: fit-content;
        color: $clr-black-700;
        font-size: $font-xl;
        font-weight: $font-500;
        line-height: 140%;
      }
    }
  }

  .dragWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 54px;
    gap: 10px;
    flex-shrink: 0;
    width: 100%;

    .dragDropBox {
      display: flex;
      width: 100%;
      height: 75vh;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.12);
      // overflow: auto;
      .buttonWrapper {
        display: flex;
        width: 100%;
        height: 92px;
        padding: 10px 40px 5px 40px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        background: #fbfdfe;

        .animateBtnWrapper {
          max-width: 130px;
          width: 100%;
          position: relative;
          z-index: 0;
          overflow: hidden;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          .borderGlow {
            position: absolute;
            z-index: 0;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 130px;
            height: 50px;
            overflow: hidden;
            filter: blur(1px);

            &::before {
              content: '';
              z-index: -2;
              text-align: center;
              top: 50%;
              left: 50%;
              // transform: translate(-50%, -50%) rotate(0deg);
              position: absolute;
              width: 200px;
              height: 200px;
              background-repeat: no-repeat;
              background-position: 0 0;
              // animation: rotate 4s linear infinite;
            }

            &.withItems::before {
              background-image: conic-gradient(
                rgba(0, 0, 0, 0),
                #508527,
                rgba(0, 0, 0, 0) 25%
              );
            }
          }

          :global(.download) {
            max-width: 122px;
            position: absolute;
            z-index: 0;
            width: 100%;
            height: 44px;
            transition:
              all 0.3s ease,
              filter 0.3s ease;
            backface-visibility: hidden;
            will-change: all;

            &.glowing {
              animation: glowingGreen 1.3s infinite;
              &:hover {
                transform: scale(1.05);
                -webkit-font-smoothing: subpixel-antialiased;
                perspective: 2500px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
              }
            }
          }
        }

        @keyframes rotate {
          0% {
            transform: translate(-50%, -50%) scale(1.4) rotate(0);
          }

          100% {
            transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
          }
        }

        @keyframes glowingGreen {
          0% {
            box-shadow: 0 0 5px #b3f0a0;
          }

          50% {
            box-shadow: 0 0 20px #49e819;
          }

          100% {
            box-shadow: 0 0 5px #2ba805;
          }
        }
      }

      .heading {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        justify-content: flex-start;
        .content {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .contentHeader {
            max-width: 180px;
            width: 100%;
            text-align: center;
          }

          .contentSpacer {
            width: 100%;
            // max-width: 550px;
            height: 1px;
            background: none;
          }

          h1 {
            padding: 0px 30px;
            font-size: $font-base;
            font-weight: $font-700;
            width: 100%;
            max-width: 400px;
          }
          .main {
            display: flex;
            align-items: baseline;
            justify-content: space-evenly;
            gap: 33px;
            width: 100%;
            p {
              padding: 0px 20px;
              font-size: $font-base;
              font-weight: $font-700;
            }
          }
          .empty {
            width: 100%;
            max-width: 200px;
          }
        }
      }

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        width: 100%;

        .dropzone {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 30px;
          padding: 0px 10px;
          width: 100%;
          height: 100%;

          p {
            color: $clr-black-700;
            font-size: $font-xl;
            font-weight: $font-400;
          }

          .before {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
          }

          .afterWrapper {
            display: flex;
            width: 100%;
            max-width: 490px;
            padding: 12px 0px;
            justify-content: center;
            align-items: center;
            gap: 36px;

            .icon {
              display: flex;
              align-items: flex-start;
            }

            .afterDrag {
              display: flex;
              width: 383px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 12px;
              font-size: $font-lg;
            }
          }
        }

        aside {
          width: 100%;
          max-width: 584px;
          display: flex;
          flex-direction: column;
          padding: 12px 36px;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;

          .heading {
            width: 100%;
            max-width: 584px;
            display: flex;
            padding: 0px 20px;
            align-items: center;
          }

          .lists {
            width: 100%;
            max-width: 584px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 20px;
            gap: 10px;

            ul {
              padding: 0px;
              width: 100%;
              max-width: 575px;
            }

            li {
              display: flex;
              align-items: center;
              gap: 10px;
              list-style: none;
              width: 100%;

              .fileInfo {
                width: 100%;
                display: flex;
                justify-content: space-between;

                p {
                  color: $clr-black-700;
                  font-size: $font-base;
                  font-weight: $font-400;
                }

                .right {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  p {
                    color: $clr-black-700;
                    font-size: $font-xs;
                    font-weight: $font-400;
                  }
                }
              }
            }
          }
        }
      }

      .buttonWrapper {
        display: flex;
        width: 100%;
        height: 92px;
        padding: 10px 40px 5px 40px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        background: #fbfdfe;
      }
    }
  }
}
@media (max-width: 1024px) {
  .wrapper {
    .dragWrapper {
      .dragDropBox {
        .heading {
          .content {
            h1 {
              font-size: 14px;
              padding-right: 110px;
              width: unset;
            }
            .main {
              gap: 50px;
              justify-content: flex-start;
              max-width: fit-content;
              // flex-wrap: wrap;
              p {
                padding: 0px;
                font-size: $font-sm;
                justify-content: flex-start;
              }
            }
            .empty {
              max-width: 170px;
            }
          }
        }
      }
    }
  }
  .contentSpacer {
    width: unset !important;
  }

  .contentHeader {
    max-width: unset !important;
    width: 100%;
    text-align: unset !important;
  }
}
@media screen and (max-width: 1360px) {
  .mobile_ch {
    display: none;
  }
  .content {
    .contentHeader {
      width: 100%;
      max-width: 200px !important;
      h1 {
        padding-right: 85px !important;
      }
    }
  }
  .contentSpacer {
    display: none;
  }
}
@media screen and (max-width: 1540px) {
  .mobile_ch {
    display: none;
  }
}
@media (max-width: 767px) {
  .wrapper {
    .dragWrapper {
      padding: 10px 5px;
      .dragDropBox {
        .heading {
          padding: 10px;
          .content {
            max-width: fit-content;

            h1 {
              font-size: $font-sm;
              padding: 0px 15px 0px 10px;
              width: 100%;
            }
            .main {
              gap: 12px;
              p {
                padding: 0px;
                font-size: 14px;
              }
            }
            .empty {
              max-width: fit-content;
            }
          }
        }

        .container {
          .dropzone {
            gap: 16px; // Adjust if needed
            padding: 10px;
            p {
              font-size: $font-lg; // Adjust if needed
            }

            .before,
            .afterWrapper {
              gap: 12px;
            }

            aside {
              padding: 5px 16px;
              .heading {
                padding: 0px;
              }
              .lists {
                gap: 8px;

                li {
                  .fileInfo {
                    p {
                      font-size: $font-base; // Adjust if needed
                    }
                    .right {
                      gap: 8px;
                      p {
                        font-size: $font-xs; // Adjust if needed
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .buttonWrapper {
          padding: 5px 10px 5px 10px;
        }
      }
    }
  }
}
