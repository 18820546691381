
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.editModal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 20px 0px;
  width: 100%;

  .editWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 397px;
      border-bottom: 1px solid rgba(126, 126, 126, 0.20);

      .cardWrapper {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        align-items: flex-start;
        gap: 6px;

        .defaultWrapper {
          display: flex;
          padding: 0px 3px 0px 5px;
          align-items: flex-start;
          justify-content: center;
          gap: 6px;
          flex-shrink: 0;

          h5 {
            color: $clr-black-700;
            font-size: $font-base;
            font-weight: $font-700;
            line-height: 100%;
          }
        }

        .cardDefault {
          display: flex;
          width: 100%;
          max-width: 223px;
          height: 100%;
          max-height: 26px;
          padding: 0px 3px 0px 5px;
          align-items: center;
          gap: 6px;
        }

        .default {
          display: flex;
          padding: 4px 5px;
          justify-content: space-between;
          align-items: center;
          flex-shrink: 0;
          border-radius: 4px;
          border: 0.5px solid #0038C8;
          color: #0038C8;
          font-size: 10px;
          font-weight: 400;
          line-height: 100%;
          cursor: pointer;
        }

        .setDefaultWrapper {
          padding-left: 5px;
          padding-bottom: 5px;

          .setDefault {
            display: flex;
            width: 100%;
            max-width: 90px;
            padding: 2px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            border: 0.5px solid #888;
            color: #888;
            font-size: 10px;
            font-style: normal;
            font-weight: $font-400;
            line-height: 100%;
          }
        }
      }

      .cardIcon {
        display: flex;
        align-items: center;
        gap: 10px;

        svg {
          cursor: pointer;
        }

        img {
          width: 36px;
          height: 22px;
        }
      }
    }
  }

  .title {
    font-size: $heading-1;
  }

  .buttonWrapper {
    display: flex;
    width: 100%;
    max-width: 400px;
    padding-block: 15px;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    background: #FBFDFE;
  }
  .columnAlignCenter{
    flex-direction: column;
    align-items: center;
  }
}
