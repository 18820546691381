
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - 73px);
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;

    .textCenter{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  
    .cover {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
  
      h1 {
        width: 100%;
        max-width: fit-content;
        padding: 5px 0px 0px 58px;
        color: $clr-black-700;
        font-size: $font-xl;
        font-weight: $font-500;
        line-height: 140%;
      }

      .uploadButton {
        padding: 5px 0px 0px 58px;
      }
    }
  
    .dragWrapper {
      display: flex;
      flex-direction: column;
      padding: 20px 54px;
      gap: 10px;
      flex-shrink: 0;
      width: 100%;
  
      .dragDropBox {
        display: flex;
        width: 100%;
        height: 75vh;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        background: #FFF;
        box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.12);
        flex-wrap: wrap;
        background-color: aqua;
  
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 30px;
          width: 100%;
          height: 80%;
  
          .dropzone {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 30px;
            padding: 0px 10px;
            width: 100%;
            height: 100%;
  
            p {
              color: $clr-black-700;
              font-size: $font-xl;
              font-weight: $font-400;
            }
  
            .before {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 30px;
            }
  
            .afterWrapper {
              display: flex;
              width: 100%;
              max-width: 490px;
              padding: 12px 0px;
              justify-content: center;
              align-items: center;
              gap: 36px;
  
              .icon {
                display: flex;
                align-items: flex-start;
              }
  
              .afterDrag {
                display: flex;
                width: 383px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 12px;
                font-size: $font-lg;
              }
            }
          }
  
          aside {
            width: 100%;
            max-width: 584px;
            display: flex;
            flex-direction: column;
            padding: 12px 36px;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
  
            .heading {
              width: 100%;
              max-width: 584px;
              display: flex;
              padding: 0px 20px;
              align-items: center;
            }
  
            .lists {
              width: 100%;
              max-width: 584px;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0px 20px;
              gap: 10px;
  
              ul {
                padding: 0px;
                width: 100%;
                max-width: 575px;
              }
  
              li {
                display: flex;
                align-items: center;
                gap: 10px;
                list-style: none;
                width: 100%;
  
                .fileInfo {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
  
                  p {
                    color: $clr-black-700;
                    font-size: $font-base;
                    font-weight: $font-400;
                  }
  
                  .right {
                    display: flex;
                    align-items: center;
                    gap: 10px;
  
                    p {
                      color: $clr-black-700;
                      font-size: $font-xs;
                      font-weight: $font-400;
                    }
                  }
                }
              }
            }
          }
        }
  
        .buttonWrapper {
          display: flex;
          width: 100%;
          height: 92px;
          padding: 10px 40px 5px 40px;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          background: #FBFDFE;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .wrapper{
    .cover{
      // max-width: 300px;
      h1{
        font-size: 24px;
        padding: 5px 0px 0px 20px;

      }
      .uploadButton {
        padding: 5px 0px 0px 20px;
        max-width: fit-content;
        button{
          max-width: fit-content;
          padding: 5px;
        }
      }
    }
  }
  }
  @media (max-width: 900px) {
    .wrapper{
      flex-direction: column; 
    }
  }