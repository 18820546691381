
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/design-tokens/colors';

.card {
  display: flex;
  width: 100%;
  max-width: 160px;
  height: 100px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  will-change: transform;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    transform: scale(1.05);
  }

  .verticalLine {
    width: 100%;
    max-width: 85px;
    height: auto;
    flex-shrink: 0;
    border-radius: 6px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 5px;

      svg {
        touch-action: manipulation;
        transition: transform 0.2s ease;

        &:active {
          transform: scale(1.2);
        }
      }

    }

    .menu {
      display: flex;
      width: 100%;
      max-width: fit-content;
      height: 63px;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      border-radius: 6px;
      position: absolute;
      right: 14px;
      bottom: 5px;
      border: 0.2px solid rgba(0, 0, 0, 0.20);
      background: #FFF;

      .menuItem {
        display: flex;
        height: 20px;
        padding: 8px 12px;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        align-self: stretch;
        cursor: pointer;
        color: #455A64;
        font-size: 12px;
        font-weight: 400;
        z-index: 999;

        &:hover {
          padding: 8px 12px;
          border-radius: 5px;
          background: #F0F5FF;
        }
      }
    }

    .center {
      width: 100%;
      max-width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      padding-left: 5px;
      cursor: pointer;

      .title {
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .dotsMenuIcon {
      width: 24px;
      height: 24px;
      cursor: pointer;
      padding-block: 4px;
      z-index: 999;
      border-radius: 50%;

      &:hover {
        mix-blend-mode: overlay;
        // mix-blend-mode: color-burn;
        background-color: rgba(214, 214, 216, 0.644);
      }
    }

    .read {
      height: 35px;
      padding: 5px 5px 5px 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .readCaption {
        font-size: 12px;
        color: #00800D;
      }
      @keyframes smoothBlink {
        0%, 100% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
      }
      
      .blinking {
        animation: smoothBlink 1.2s ease-in-out infinite;
      }      
    }
  }
}