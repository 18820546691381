
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.notificationModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;

  .title {
    color: $clr-black-700;
    font-size: $font-xl;
    font-style: normal;
    font-weight: $font-500;
    line-height: 100%;
  }
  .itemWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .buttonWrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 400px;
    padding: 15px 15px 0px;
    // flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: #fbfdfe;
  }
  @media (max-width: 767px) {
    .title {
      font-size: $font-lg;
    }
  }
}
