
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.sidebar {
    display: flex;
    width: 100%;
    max-width: 220px;
    height: calc(100vh - 73px);
    padding: 16px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: var(--light-base-color, #F8F8F9); 
    padding: 20px;

    .userDetailWrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .allUsers{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        svg{
          cursor: pointer;
          touch-action: manipulation;
          transition: transform 0.2s ease;
    
          &:active {
            transform: scale(0.9);
          }
        }
    }
    .userName{
        width: 100%;
        padding: 2px 16px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 2px;
        color: #808192;
        font-size: $font-sm;
        font-style: normal;
        font-weight: $font-500;
        line-height: 100%;
    }
    @media (max-width: 767px) {
        max-width:120px;
        p{
            font-size: 14px;
            padding: 10px;
          }
        .allUsers {
          font-size: 14px;
         
        }
        .userName {
          padding: 2px 0;
        }
      }
  }

  @media (max-width: 900px) {
    .allUsers{
      justify-content: left !important;
      padding: 0;
    }
    .sidebar{
      max-width: unset;
      height: unset;
      flex-direction: column;
      // padding: 0px 8px 0px 16px;
    }
    .userDetailWrapper{
      display: flex;
      width: 100%;
      align-items: baseline;
      flex-direction: column;
    }
  }