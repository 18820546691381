
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: calc(100vh - 92px);
  height: 100%;
  align-items: center;
  gap: 4px;
  justify-content: center;

  .dragWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    max-height: 588px;
    padding: 20px;
    justify-content: space-between;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
      .dropzone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // gap: 30px;
        padding: 0px 10px;
        width: 100%;
        height: 100%;

        p {
          color: $clr-black-700;
          // font-size: $font-xl;
          font-size: 28px;
          font-weight: $font-400;
          cursor: pointer;
          text-align: center;
        }

        .before {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 30px;
          position: absolute;
          top: 25%;
        }

        .afterWrapper {
          display: flex;
          width: 100%;
          max-width: 490px;
          padding: 12px 0px;
          justify-content: center;
          align-items: center;
          gap: 36px;

          .icon {
            display: flex;
            align-items: flex-start;
          }

          .afterDrag {
            display: flex;
            width: 100%;
            max-width: 383px;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;
            font-size: $font-lg;
            flex-wrap: wrap;
          }
        }
      }
      svg {
        cursor: pointer;
      }
    }
    aside {
      width: 100%;
      // max-width: 584px;
      max-width: 490px;
      height: fit-content;
      overflow: auto;
      display: flex;
      flex-direction: column;
      // padding: 12px 36px;
      // justify-content: center;
      align-items: flex-start;
      gap: 5px;
      position: absolute;
      top: 30%;

      .heading {
        width: 100%;
        max-width: 584px;
        display: flex;
        padding: 0px 20px;
        align-items: center;
      }

      .lists {
        width: 100%;
        max-width: 584px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 20px;
        gap: 10px;
        overflow: auto;
        height: auto;
        max-height: 200px;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: #1e4178;
          outline: 1px solid slategrey;
        }

        ul {
          padding: 0px;
          width: 100%;
          max-width: 575px;
          height: 100%;
        }

        li {
          display: flex;
          align-items: center;
          gap: 10px;
          list-style: none;
          width: 100%;

          .fileInfo {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .fileName {
              width: 100%;
              text-align: left;
              width: 100%;
              max-width: 200px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
            }

            p {
              color: $clr-black-700;
              font-size: $font-base;
              font-weight: $font-400;
            }

            .right {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;
              justify-content: flex-end;
              svg {
                cursor: pointer;
              }

              p {
                color: $clr-black-700;
                font-size: $font-xs;
                font-weight: $font-400;
              }
            }
          }
        }
      }
    }
  }
  .buttonWrapper {
    display: flex;
    // width: 100%;
    // height: 92px;
    padding: 10px 40px 5px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: -webkit-fill-available;
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 768px) {
    .dragWrapper {
      aside {
        .lists {
          li {
            .fileInfo {
              p {
                font-size: $font-sm;
                word-break: break-all;
                // max-width: 100px;
              }
            }
          }
        }
      }
      .container {
        .dropzone {
          p {
            font-size: $font-md;
          }
          .afterWrapper {
            .afterDrag {
              // TODO: verify
              // font-size: $font-md;
              font-size: 16px;
            }
          }
        }
      }
    }
    .buttonWrapper {
      padding: 10px 10px 5px 10px;
    }
  }
}
