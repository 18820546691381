
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.dashBoardWrapper {
    display: flex;
    padding: 78px 15px;
    align-items: flex-start;
  
    .dashBoard {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    .textCenter{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  
    .boxContent {
      display: flex;
      width: 100%;
      height: 100%; /* Adjusted for smaller screens */
      padding: 15px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      background: #FFF;

      @media screen and (max-width: 1099px) {
        padding: unset;
      }

      .renderTextWithIconCopyIconWrapper{
        display: flex;
        align-items: center;
        svg{
          path{
            fill: rgb(226, 226, 2);
          }
        }
        .greenIcon{
          svg{
            path{
              fill: green;
            }
          }
        }
      }
      .greenIcon{
        svg{
          path{
            fill: green;
          }
        }      
      }

      .orderList {
        padding-left: 20px;
        font-size: 20px;
        @media (max-width: 1240px) {
          font-size: 18px;
        }
        @media (max-width: 620px) {
          font-size: 2.5vw;
        }
        li {
          &:first-child {
            margin-bottom: 10px; // Adjust the value as needed
          }
          
          &:last-child {
            margin-top: 10px; // Adjust the value as needed
          }
          
          &:not(:first-child):not(:last-child) {
            margin-block: 10px; // Adjust the value as needed
          }
        }
      }
  
      h1 {
        color: $clr-navy-100;
        font-size: $font-lg;
        font-style: normal;
        font-weight: $font-500;
        line-height: 100%; /* 24px */
      }
  
      .image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  
    @media only screen and (max-width: 768px) {
      padding: 30px 10px; 
  
      .dashBoard {
        gap: 10px;
      }
  
      .boxContent {
        width: unset;
        padding: 10px;
        h1{
            font-size: $font-md;
        }
        .iconPara{
            
            p{
                width: 100%;
                font-size: $font-sm;
            }
        }
        .image{
            svg{
                max-width: 100%;
            }
        }
      }
    }
    @media only screen and (max-width: 968px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      .boxContent{
        max-width: 600px;
      }
    }
  }
  