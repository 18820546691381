
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.colorOption {
  &.orange,
  &.navy,
  &.brown,
  &.lightgreen,
  &.darkgreen,
  &.blue,
  &.red,
  &.black {
    background-color: $space-colors;
    will-change: transform;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover{
      transform: scale(1.05);
    }
  }
  input[type='radio'] {
    display: none;
    border: none;
    border-radius: 0;
    &:checked + label {
      .checkmark {
        &::before {
          content: '\2713';
          font-size: 14px;
          color: white;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  label {
    display: inline-block;
    width: 24px;
    height: 19px;
    border-radius: 2px;
    cursor: pointer;
    border: none;
  }
  .checkmark {
    border-radius: unset;
    position: relative;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    display: flex;
    border: none;
  }
}
