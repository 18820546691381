
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20vh 0px;
    gap: 15px;

    .logoWrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 0px;
        gap: 24px;

        h1{
            font-size: $font-lg;
            font-weight: $font-700;
        }
    }
}