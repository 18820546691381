.willeder-button {
  width: 100%;
  max-width: 404px;
  height: 43px;
  padding: 10px 0px;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: $font-500;
  font-size: $font-base;
  line-height: normal;

  touch-action: manipulation;
  will-change: transform;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover{
    transform: scale(1.05);
  }
  
  &.test {
    background: $clr-navy-200;
    color: $clr-white-100;
  }
  &.outlined {
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid $clr-black-100;
    color: $clr-black-700;
    gap: 6px;
  }
  &.buttonGrey {
    max-width: 520px;
    background-color: #c9c9c9;
    color: $clr-black-200;
  }
  &.close {
    background: #bebebe;
    color: $clr-black-700;
  }
  &.buttonBlue {
    max-width: 520px;
    background-color: $clr-navy-200;
    color: $clr-white-100;
  }
  &.confirmButton {
    max-width: 148px;
    height: 46px;
    background-color: $clr-navy-200;
    color: $clr-white-100;
  }
  &.noButton {
    max-width: 148px;
    height: 46px;
    background-color: $clr-red-200;
    color: $clr-white-100;
  }
  &.update {
    background-color: $clr-navy-200;
    color: $clr-white-100;
  }
  &.delete {
    background-color: $clr-red-200;
    color: $clr-white-100;
  }
  &.addButton {
    max-width: 171px;
    background-color: $clr-navy-200;
    color: $clr-white-100;
    gap: 6px;
    @media (max-width: 767px) {
    font-size: 14px;    
    padding: 0px;
    min-height: fit-content;
}
  }
  &.upload {
    max-width: 122px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
    color: rgba(0, 0, 0, 0.3);
    font-size: 14px;
    font-weight: $font-400;
  }
  &.cancelButton {
    max-width: 100px;
    background-color: #fff;
    border: 1px solid $clr-red-200;
    color: $clr-red-200;
    font-size: $font-sm;
    font-weight: $font-400;
    @media (max-width: 767px) {
      font-size: 14px;    
      padding: 5px;
      max-width: fit-content;
  }
  }
  &.activeUpload {
    max-width: 122px;
    // background-color: #fff;
    background-color: transparent;
    border: 1px solid $clr-sky-200;
    color: $clr-sky-200;
    font-size: 14px;
    font-weight: 400;
    gap: 5px;
  }
  &.selectButton {
    max-width: 280px;
    height: 46px;
    background-color: #fbfdfe;
    border: 1px solid rgba(15, 145, 210, 0.7);
    color: $clr-sky-200;
    text-transform: uppercase;
    font-size: $font-md;
    font-weight: $font-400;
  }
  &.mw280{ max-width: 280px; }
  &.capitalize{text-transform: capitalize;}
  &.alterText{
    font-size: 18px;
    font-weight: 400;
    height: 46px;
  }
  &.selectButton_after {
    max-width: 115px;
    background-color: #fbfdfe;
    border: 1px solid rgba(15, 145, 210, 0.7);
    color: $clr-sky-200;
    text-transform: uppercase;
    font-size: $font-sm;
    font-weight: $font-400;
  }
  &.deleteButton {
    background: transparent;
    border: 1px solid $clr-navy-200;
    color: $clr-navy-200;
  }
  &.turnOff {
    max-width: 76px;
    height: 24px;
    background: transparent;
    border: 1px solid $clr-red-200;
    color: $clr-red-200;
    font-size: 10px;
    font-weight: $font-400;
    border-radius: 0px;
  }
  &.turnOn {
    max-width: 76px;
    height: 24px;
    background: transparent;
    border: 1px solid $clr-green-300;
    color: $clr-green-300;
    font-size: 10px;
    font-weight: $font-400;
    border-radius: 0px;
  }
  &.basic {
    max-width: 133px;
    display: flex;
    justify-content: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid $clr-navy-200;
    background: rgba(255, 255, 255, 0);
    color: $clr-navy-200;
    font-size: $font-lg;
    font-weight: $font-500;
  }
  &.premium {
    max-width: 133px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1px solid $clr-white-100;
    background: rgba(243, 243, 243, 0);
    color: $clr-white-100;
    font-size: $font-lg;
    font-weight: $font-500;
  }
  &.choosePlan {
    display: flex;
    max-width: 231px;
    height: 55px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #d3e7f0;
  }
  &.download {
    max-width: 122px;
    background-color: #fff;
    border: 1px solid #508527;
    color: #508527;
    font-size: 14px;
    font-weight: 400;
    gap: 5px;
    @media (max-width: 767px) {
      font-size: 14px;    
      padding: 5px;
      max-width: fit-content;
  }
  }
  &:disabled {
    border-color: gray;
    color: gray;
  }

  &.full-width{
    max-width: 100%;
    width: 100%;
  }

}
