
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
@import '../../../styles/main.scss';

.fileModal {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  padding: 20px 0px;
  min-width: 300px;
  width: 100%;

  .title {
    font-size: $heading-1;
    color: $clr-black-700;
  }
  .fileWrapper {
    display: flex;
    width: 100%;
    max-width: 356px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .progressBar {
      height: 20px;
      width: 100%;
      flex-shrink: 0;
    }

    .uploadInfo {
      color: #1e4178;
      font-size: $font-sm;
      font-weight: $font-400;
      line-height: normal;
      display: flex;
      gap: 10
    }
  }

  .wrapper {
    display: flex;
    width: 100%;
    max-width: 374px;
    height: 100%;
    max-height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2b2b2c;
      outline: 1px solid slategrey;
    }

    .fileInfo {
      display: flex;
      width: 100%;
      // max-width: 390px;
      padding-right: 10px;
      justify-content: space-between;
      align-items: center;
      color: $clr-black-700;
      font-size: $font-sm;
      font-weight: $font-500;
      line-height: normal;

      .fileName{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      .size {
        width: 100%;
        // max-width: 116px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $clr-black-700;
        font-size: $font-xs;
        font-weight: $font-400;
        line-height: normal;

        .statusInfo {
          color: #1e4178;
          font-size: $font-sm;
          font-weight: $font-400;
          line-height: normal;
          margin-left: 20px;

          // .waiting {
          //   color: $clr-yellow-100;
          //   font-style: italic;
          //   font-size: $font-xs;
          // }
          // .encrypted,
          // .encrypting {
          //   color: $clr-green-300;
          //   font-weight: $font-300;
          //   font-size: $font-xs;
          // }
          // .decrypted,
          // .decrypting {
          //   color: $clr-green-300;
          //   font-weight: bold;
          //   font-size: $font-xs;
          // }
        }
        .waiting {
          color: $clr-yellow-100;
          font-style: italic;
          font-size: $font-xs;
        }
        .encrypted,
        .encrypting {
          color: $clr-green-300;
          font-weight: $font-300;
          font-size: $font-xs;
        }
        .decrypted,
        .decrypting {
          color: $clr-green-300;
          font-weight: bold;
          font-size: $font-xs;
        }
      }
    }
  }

  .buttonWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}
