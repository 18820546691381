
          @import "src/styles/design-tokens/_colors.scss";
          @import "src/styles/design-tokens/_typography.scss";
        
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20vh 0px;
  gap: 15px;

  .logoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0px;
    gap: 24px;

    h1 {
      font-size: $font-lg;
      font-weight: $font-700;
    }
    p {
      color: $clr-black-700;
      font-size: $font-sm;
      font-weight: $font-400;
    }
  }
  .otpWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .textWrapper {
      width: 100%;
      max-width: 520px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px 3px;
      p {
        color: $clr-black-700;
        font-size: $font-sm;
        font-weight: $font-400;
        line-height: 100%; /* 14px */

        span {
          cursor: pointer;
          color: #1f92fc;
          display: inline-block;
          font-weight: bold;
          &:hover {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
